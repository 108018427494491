import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loader: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController) {}

  async show() {
    if (!this.loader) {
      this.loader = await this.loadingCtrl.create({
        spinner: 'crescent',
      });
      await this.loader.present();
    }
  }

  hide() {
    if (this.loader) {
      this.loader.dismiss().then((res) => {
        if (res) {
          this.loader = null;
        }
      });
    }
  }
}
