import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
/// firebase ///

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { SharedModule } from './pages/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { animation } from '@angular/animations';
//
import { SwiperModule } from 'swiper/angular';
import { MenuComponent } from './pages/shared/menu/menu.component';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
// import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    IonicModule.forRoot({ mode: 'ios' }),
    //  animated: false
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    SharedModule,
    BrowserAnimationsModule,
    MatMenuModule,
    SwiperModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbPaginationModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MediaCapture,
    File,
  ],
  bootstrap: [AppComponent],
  entryComponents: [MenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
