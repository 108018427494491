/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable curly */
/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Notice } from 'src/app/models/notice';
import { AuthService } from 'src/services/auth.service';
import { DbService } from 'src/services/firebase.service';
import { Post } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { DataService } from 'src/services/data.service';

@Component({
  selector: 'menu-shared',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  contentView = true;
  searchShow = false;
  uploadShow = false;
  chatShow = false;
  notificationsShow = false;

  contentList: Array<Post> = [];
  userList: Array<User> = [];
  segment = 'content';
  keyword;

  noticeId: Notice['id'] = '';
  noticeList: Array<Notice> = [];
  isNotReadNotice = false;

  chatList$: Observable<Array<any>> = of([]);
  // searchSeg = 'content';

  path = '';

  constructor(
    private navc: NavController,
    public auth: AuthService,
    private db: DbService,
    private activatedRoute: ActivatedRoute,
    private data: DataService,
    private router: Router
  ) {
    const aa = this.auth.getAuth();
    this.path = this.router.url;

    this.subscribeSearchMenu();

    this.activatedRoute.queryParams.subscribe(({ keyword }) => {
      if (keyword) {
        this.keyword = keyword;
      } else if (this.keyword) {
        this.searchKeyword();
      }
    });

    this.activatedRoute.paramMap.subscribe(() => {
      this.getNoticeList();
      this.noticeId = this.activatedRoute.snapshot.paramMap.get('id');
    });
  }
  async ngOnInit() {
    this.getChatList();
    await Promise.all([this.data.getPost(), this.data.getUser()]);
  }

  ionViewDidLeave() {
    this.keyword = '';
    this.contentList = [];
    this.userList = [];
  }

  // 게시물 상세에서 클릭한 태그 정보를 검색 메뉴로 전달받기 위한 함수
  subscribeSearchMenu() {
    // NOTE 초기화해주지 않으면 정상적으로 동직하지 않는 이유로 인해
    // 만약 값이 존재한다면 구독 전 초기화
    if (this.data.isActiveSearchSub) {
      this.data.isActiveSearchSub.unsubscribe();
      this.data.isActiveSearchSub = null;
    }

    // 구독
    if (!this.data.isActiveSearchSub) {
      this.data.isActiveSearchSub = this.data.searchSub.subscribe(
        ({ type, keyword }) => {
          if (type && keyword) {
            this.showSearch();
            this.keyword = keyword;
            this.searchKeyword();
          }
        }
      );
    }
  }

  // 채팅 목록 불러오기
  async getChatList() {
    if (!this.auth.user) {
      return;
    }

    this.chatList$ = this.db
      .collection$('chat', (ref: any) =>
        ref
          .where('userId', 'array-contains', this.auth.user?.uuid)
          .orderBy('dateCreated', 'desc')
      )
      .pipe(
        switchMap((chatList) => {
          const filterChatList = chatList.filter(
            (chat) => !chat[this.auth.user.uuid].exitSwitch
          );
          return filterChatList.length >= 1
            ? combineLatest(
                filterChatList.map((chat) => {
                  const partnerId =
                    this.auth.user.uuid === chat.userId[0]
                      ? chat.userId[1]
                      : chat.userId[0];
                  return this.db.doc$(`members/${partnerId}`).pipe(
                    map((partner) => {
                      const exitIndex = chat[this.auth.user.uuid].exitIndex;
                      const lastIndex = chat[this.auth.user.uuid].lastIndex;
                      const badge =
                        chat.message.length -
                        (exitIndex > lastIndex ? exitIndex : lastIndex);
                      return { ...chat, partner, badge };
                    })
                  );
                })
              )
            : of([]);
        }),
        map((chatList) => {
          const isMessage = chatList
            .filter((chat) => chat.message.length >= 1)
            .sort(
              (a, b) =>
                new Date(
                  b.message[b.message.length - 1].dateCreated
                ).getTime() -
                new Date(a.message[a.message.length - 1].dateCreated).getTime()
            );
          const noMessage = chatList.filter((chat) => 0 >= chat.message.length);
          return isMessage.concat(noMessage);
        })
      );
  }

  // 마지막 채팅 일자 표시
  displayLastChatDate(date: string) {
    if (
      new Date(date).toLocaleDateString() === new Date().toLocaleDateString()
    ) {
      return new Date(date).toTimeString().slice(0, 5);
    } else if (
      new Date(date).toLocaleDateString() ===
      new Date(
        new Date(date).setDate(new Date().getDate() - 1)
      ).toLocaleDateString()
    ) {
      return 'Yesterday';
    } else {
      const splitDate = new Date(date)
        .toLocaleDateString()
        .replace(/(\s*)/g, '')
        .split('.');
      return `${splitDate[1]}/${splitDate[2]}`;
    }
  }

  // 검색
  searchKeyword() {
    this.contentList = this.data.postList.filter((post) => {
      if (this.keyword.replace(/[ -.]/gi, '')) {
        if (!post.hideSwitch) {
          const include = (key: string) => {
            const checkInclude = (value: string) =>
              value
                .toLowerCase()
                .replace(/[ -.]/gi, '')
                .includes(this.keyword.toLowerCase().replace(/[ -.]/gi, ''));
            if (typeof post[key] === 'string') {
              return checkInclude(post[key]);
            } else if (typeof post[key] === 'object') {
              return post[key].some((value: any) => {
                if (typeof value === 'string') {
                  return checkInclude(value);
                } else if (key === 'director' || key === 'cast') {
                  return checkInclude(value.name);
                } else if (key === 'userTag') {
                  return checkInclude(value.nickname);
                } else {
                  return false;
                }
              });
            } else {
              return false;
            }
          };
          if (post.type === 'Film') {
            return (
              include('title') ||
              include('genre') ||
              include('director') ||
              include('cast') ||
              include('synopsis') ||
              include('award') ||
              include('note')
            );
          } else if (post.type === 'Actor') {
            return (
              include('name') ||
              include('introduce') ||
              include('filmography') ||
              include('note')
            );
          } else if (post.type === 'Script') {
            return (
              include('title') ||
              include('synopsis') ||
              include('script') ||
              include('genre') ||
              include('note')
            );
          } else {
            return include('content') || include('tag') || include('userTag');
          }
        }
      }
      return false;
    });
    this.userList = this.data.userList.filter(({ nickname }) => {
      if (this.keyword.replace(/[ -.]/gi, '')) {
        return nickname
          .toLowerCase()
          .replace(/[ -.]/gi, '')
          .includes(this.keyword.toLowerCase().replace(/[ -.]/gi, ''));
      } else {
        return false;
      }
    });
  }

  goHome() {
    this.done();
    this.navc.navigateForward('/tabs/home', {
      animated: false,
    });
  }

  goMypage() {
    this.done();
    this.navc.navigateForward('/tabs/mypage', {
      animated: false,
    });
  }

  // 업로드 글쓰기
  goUploadFilmWrite() {
    this.navc.navigateForward('/tabs/upload/upload-film-write', {
      animated: false,
    });
  }

  goUploadActorWrite() {
    this.navc.navigateForward('/tabs/upload/upload-actor-write', {
      animated: false,
    });
  }

  goUploadScriptWrite() {
    this.navc.navigateForward('/tabs/upload/upload-script-write', {
      animated: false,
    });
  }

  goUploadFeedWrite() {
    this.navc.navigateForward('/tabs/upload/upload-feed-write', {
      animated: false,
    });
  }

  // 채팅상새
  goChat(chatId: string) {
    this.done();
    this.navc.navigateForward(`/tabs/chat/chat-detail/${chatId}`, {
      // queryParams: { chatId },
      animated: false,
    });
  }

  // 공지상세
  goNoticeDetail(notice: Notice) {
    this.done();
    this.navc.navigateForward(
      `/tabs/mypage/notice/notice-detail/${notice.id}`,
      {
        animated: false,
      }
    );
  }

  // 유저프로필
  goUserProfile(userId: User['uuid']) {
    this.done();
    if (userId !== this.auth.user.uuid) {
      this.navc.navigateForward(`/user-profile/${userId}`, {
        queryParams: { userId },
        animated: false,
      });
    } else {
      this.navc.navigateForward('/tabs/mypage', {
        animated: false,
      });
    }
  }

  // 게시글 상세
  goFilmDetail(post?: Post) {
    this.done();
    this.navc.navigateForward(`/tabs/home/detail-feed/${post.id}`, {
      animated: false,
      queryParams: { type: post.type },
    });
  }

  goActorDetail() {
    this.navc.navigateForward('/detail-actor', { animated: false });
  }

  goScriptDetail() {
    this.navc.navigateForward('/detail-script', { animated: false });
  }

  // 검색
  showSearch() {
    this.searchShow = true;
    this.uploadShow = false;
    this.chatShow = false;
    this.notificationsShow = false;
  }

  showUpload() {
    this.uploadShow = true;
    this.searchShow = false;
    this.chatShow = false;
    this.notificationsShow = false;
  }

  showChat() {
    this.chatShow = true;
    this.searchShow = false;
    this.uploadShow = false;
    this.notificationsShow = false;

    this.getChatList();
  }

  showNotifications() {
    this.notificationsShow = true;
    this.searchShow = false;
    this.uploadShow = false;
    this.chatShow = false;
  }

  done() {
    this.data.searchSub.next({ type: null, keyword: null });
    this.searchShow = false;
    this.uploadShow = false;
    this.chatShow = false;
    this.notificationsShow = false;
  }

  // 게시물 작성 페이지로 이동
  goWrite(type: 'Film' | 'Actor' | 'Script' | 'Feed') {
    this.done();
    this.navc.navigateForward(`/tabs/upload/upload-film-write/${type}`, {
      // queryParams: { type },
      animated: false,
    });
  }

  /* SECTION - 검색 관련 메서드 */

  /* SECTION - 공지사항 관련 메서드 */
  // 읽지 않은 공지사항이 있는지 체크
  checkNotReadNotices() {
    this.isNotReadNotice = this.noticeList.some(
      (notice) => !notice.confirmUsers.includes(this.auth.user.uuid)
    );
  }

  // 공지사항 불러오기
  async getNoticeList() {
    if (!this.auth.user) {
      return;
    }

    this.noticeList = await this.db
      .collection$('notice', (ref: any) => ref.orderBy('dateCreated', 'desc'))
      .pipe(
        map((notices: Notice[]): Notice[] => {
          const uuid = this.auth.user.uuid;
          return notices.map((notice) => {
            if (
              notice.confirmUsers &&
              notice.id === this.noticeId &&
              !notice.confirmUsers.includes(uuid)
            ) {
              return {
                ...notice,
                confirmUsers: [...notice.confirmUsers, uuid],
              };
            }
            return notice.confirmUsers
              ? notice
              : { ...notice, confirmUsers: [] };
          });
        }),
        take(1)
      )
      .toPromise();
    this.checkNotReadNotices();
  }

  // 공지사항 업로드 경과 시간
  displayedAt(createdAt: string) {
    const milliSeconds = new Date().getTime() - moment(createdAt).valueOf();
    const seconds = milliSeconds / 1000;
    if (seconds < 60) return `a moment ago`;
    const minutes = seconds / 60;
    if (minutes < 60) return `${Math.floor(minutes)} Minute ago`;
    const hours = minutes / 60;
    if (hours < 24) return `${Math.floor(hours)} Hour ago`;
    const days = hours / 24;
    if (days < 7) {
      if (days === 1) {
        return `${Math.floor(days)} Day ago`;
      } else {
        return `${Math.floor(days)} Days ago`;
      }
    }
    const months = days / 30;
    if (months < 12) {
      if (months === 1) {
        return `${Math.floor(months)} Month ago`;
      } else {
        return `${Math.floor(months)} Months ago`;
      }
    }
    return moment(createdAt).format('YY.MM.DD');
  }
}
