import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
import { Admin } from 'src/app/models/admin';
import { User } from 'src/app/models/user';
import { DbService } from './firebase.service';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { NavController } from '@ionic/angular';
import { PushService } from './push.service';
import { arrayRemove } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  admin: Admin;
  user: User;

  authenticationStatus = new BehaviorSubject(false);

  constructor(
    private afAuth: AngularFireAuth,
    private db: DbService,
    private navCtrl: NavController,
    private push: PushService
  ) {
    this.db.doc$('admin/admin').subscribe((admin) => {
      this.admin = admin;
    });
    this.afAuth.authState
      .pipe(
        switchMap((user) => {
          console.log('user', user);

          return user ? this.db.doc$(`members/${user.uid}`) : of(null);
        })
      )
      .subscribe((user) => {
        this.user = user;
        console.log('this.user', this.user);

        if (this.user && this.user.authenticationStatus === 'approval') {
          this.authenticationStatus.next(true);
        }
        if (this.user && this.user.suspendSwitch) {
          this.navCtrl.navigateRoot('/suspension');
        }
      });
  }

  // wait auth
  getAuth() {
    return new Promise<boolean>((resolve) => {
      const wait = () => {
        if (this.admin && this.user) {
          resolve(true);
        } else {
          setTimeout(() => wait(), 10);
        }
      };
      wait();
    });
  }

  // 회원가입
  registerUser(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password).then(
        ({ user: { uid } }) => resolve(uid),
        ({ code }) => reject(code)
      );
    });
  }

  // 로그아웃
  async logoutUser() {
    await this.push.getId().then(async (pushId) => {
      if (pushId) {
        await this.db.updateAt(`members/${this.user.uuid}`, {
          pushId: arrayRemove(pushId),
        });
      }
    });
    await this.afAuth.signOut();
  }

  // 로그인
  loginUser(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password).then(
        ({ user: { uid } }) => resolve(uid),
        ({ code }) => reject(code)
      );
    });
  }

  // 비밀번호 재설정 메일 발송
  sendResetPasswordEmail(email: string) {
    return new Promise<any>((resolve, reject) => {
      return this.afAuth.sendPasswordResetEmail(email).then(
        () => resolve(true),
        ({ code }) => reject(code)
      );
    });
  }

  // 회원탈퇴
  exitUser() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.currentUser.then(async (user) => {
        await this.db.updateAt(`members/${user.uid}`, {
          exitSwitch: true,
          pushId: [],
        });
        user.delete().then(
          () => resolve(true),
          ({ code }) => reject(code)
        );
      });
    });
  }
}
