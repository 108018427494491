import { Component, HostListener } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from '@capacitor/text-zoom';
import { AlertService } from 'src/services/alert.service';
import { Device } from '@capacitor/device';
import OneSignal from 'onesignal-cordova-plugin';
import { AuthService } from 'src/services/auth.service';
import { filter } from 'rxjs/operators';
import { DbService } from 'src/services/firebase.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  //백버튼 시간
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  // isDesktop: boolean = window.innerWidth >= 1024;

  constructor(
    public platform: Platform,
    private router: Router,
    public navCtrl: NavController,
    public alertService: AlertService,
    private auth: AuthService,
    private db: DbService
  ) {
    this.initializeApp();
    this.backbutton();
    // this.openDeepLink();
    this.router.events
      .pipe(filter((ev) => ev instanceof NavigationStart))
      .subscribe(async (ev: RouterEvent) => {
        const currentURL = ev.url.split('?')[0];
        if (this.auth.user === undefined) {
          await this.auth.getAuth();
        }
        if (
          this.auth.user &&
          this.auth.user.chatId &&
          currentURL !== '/chat-detail'
        ) {
          this.db.updateAt(`members/${this.auth.user.uuid}`, { chatId: '' });
        }
      });
  }

  // @HostListener('window:resize', ['$event']) onResize({ target }) {
  //   const width = target.innerWidth;
  //   this.isDesktop = width >= 1024;
  // }

  //시작시 세팅

  //시작시 세팅
  async initializeApp() {
    ///TODO 안예쁘긴 한데 이게 최선이에요
    this.platform.ready().then(async (data) => {
      //디바이스 정보 가져오기
      const deviceInfo = await Device.getInfo();
      //서버로 돌릴때 진행하지 않기
      if (!this.platform.is('capacitor')) {
        return false;
      }
      this.oneSignalInit();

      //디바이스가 안드로이 12 이상일때 옵션
      if (
        deviceInfo.platform == 'android' &&
        Number(deviceInfo.osVersion) > 11
      ) {
        //유저 폰트 크기 고정
        await TextZoom.set({ value: 1 });

        SplashScreen.hide().then(async (data) => {
          await SplashScreen.show({
            autoHide: false,
          });

          setTimeout(async () => {
            await StatusBar.setBackgroundColor({
              color: '#ffffff',
            });
            await StatusBar.setOverlaysWebView({
              overlay: false,
            });
            await StatusBar.setStyle({
              style: Style.Dark,
            });
            await SplashScreen.hide();
          }, 500);
        });
      } else {
        //그외
        await TextZoom.set({ value: 1 });

        await StatusBar.setStyle({
          style: Style.Dark,
        });

        if (deviceInfo.platform == 'android') {
          await StatusBar.setBackgroundColor({
            color: '#ffffff',
          });
          await StatusBar.setOverlaysWebView({
            overlay: false,
          });
        }
        await SplashScreen.hide();
      }
    });
  }

  //백버튼 기본 셋팅
  backbutton() {
    this.platform.backButton.subscribeWithPriority(0, async () => {
      let url = this.router.url;

      switch (url) {
        case '/':
        case '/tabs/home': {
          if (
            new Date().getTime() - this.lastTimeBackPress <
            this.timePeriodToExit
          ) {
            navigator['app'].exitApp();
          } else {
            this.alertService.presentToast('다시 한번 누르면 종료됩니다.');
            this.lastTimeBackPress = new Date().getTime();
          }
          break;
        }

        default: {
          this.navCtrl.pop();
          break;
        }
      }
    });
  }

  // 원시그널 세팅
  oneSignalInit() {
    OneSignal.setAppId('8e9794b3-c66b-4f84-b965-bbc50a7db13e');
    OneSignal.promptForPushNotificationsWithUserResponse();
    OneSignal.setNotificationOpenedHandler((ev: any) => {
      const { url } = ev.notification.additionalData;
      if (url) {
        if (this.router.url.split('?')[0] === url.split('?')[0]) {
          this.navCtrl.navigateRoot('/wait');
          setTimeout(() => {
            this.navCtrl.navigateRoot(url);
          }, 100);
        } else {
          this.navCtrl.navigateForward(url);
        }
      }
    });
  }
}
