import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(private platform: Platform, private http: HttpClient) {}
  async getId() {
    if (this.platform.is('capacitor')) {
      return new Promise<string>((resolve) => {
        OneSignal.getDeviceState(({ userId }) => {
          resolve(userId);
        });
      });
    } else {
      return false;
    }
  }

  /**
   * @param url e.g. '/inquire-detail?inquiryId=WHA1idBPCJ1677481664449'
   */
  sendPush(pushId: Array<string>, message: string, url?: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      };
      const body = {
        app_id: '8e9794b3-c66b-4f84-b965-bbc50a7db13e',
        headings: { en: 'INDIE FILMART' },
        contents: { en: message },
        data: { url },
        include_player_ids: pushId,
        android_channel_id: '45f8c02c-c6f0-4ca4-9dff-f99372e9ec3e',
      };
      if (pushId.length > 0) {
        this.http
          .post('https://onesignal.com/api/v1/notifications', body, options)
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      }
    });
  }
}
