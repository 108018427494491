import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';
import { LoadingService } from 'src/services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private navCtrl: NavController,
    private loader: LoadingService
  ) {}

  async canActivate() {
    if (this.auth.user) {
      return true;
    } else {
      await this.loader.show();
      const res = await new Promise<boolean>((resolve) => {
        const wait = () => {
          if (this.auth.admin && this.auth.user) {
            resolve(true);
          } else if (this.auth.user === null) {
            resolve(false);
          } else {
            setTimeout(() => wait(), 10);
          }
        };
        wait();
      });
      this.loader.hide();
      if (res) {
        return true;
      } else {
        this.navCtrl.navigateRoot('/sign-in');
        return false;
      }
    }
  }
}
