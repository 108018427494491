import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { map, take } from 'rxjs/operators';
import { Notice } from 'src/app/models/notice';
import { AuthService } from 'src/services/auth.service';
import { DbService } from 'src/services/firebase.service';

@Component({
  selector: 'header-shared',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  noticeList: Array<Notice> = [];
  isNotReadNotice = false;
  noticeId: Notice['id'] = '';

  constructor(
    private navc: NavController,
    public auth: AuthService,
    private db: DbService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.paramMap.subscribe(() => {
      this.getNoticeList();
      this.noticeId = this.activatedRoute.snapshot.paramMap.get('id');
    });
  }

  ngOnInit() {}

  goHome() {
    this.navc.navigateForward('/tabs/home', { animated: false });
  }
  goNotifications() {
    this.navc.navigateForward('/tabs/notifications', { animated: false });
  }

  checkNotReadNotices() {
    this.isNotReadNotice = this.noticeList.some(
      (notice) => !notice.confirmUsers.includes(this.auth.user.uuid)
    );
  }

  // 공지사항 불러오기
  async getNoticeList() {
    if (!this.auth.user) {
      return;
    }

    this.noticeList = await this.db
      .collection$('notice', (ref: any) => ref.orderBy('dateCreated', 'desc'))
      .pipe(
        map((notices: Notice[]): Notice[] => {
          const uuid = this.auth.user.uuid;
          return notices.map((notice) => {
            if (
              notice.confirmUsers &&
              notice.id === this.noticeId &&
              !notice.confirmUsers.includes(uuid)
            ) {
              return {
                ...notice,
                confirmUsers: [...notice.confirmUsers, uuid],
              };
            }
            return notice.confirmUsers
              ? notice
              : { ...notice, confirmUsers: [] };
          });
        }),
        take(1)
      )
      .toPromise();
    this.checkNotReadNotices();
  }
}
