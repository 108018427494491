import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AlertService } from './alert.service';
import {
  Camera,
  ImageOptions,
  CameraResultType,
  CameraSource,
} from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import {
  getStorage,
  ref,
  uploadString,
  uploadBytes,
  deleteObject,
  getDownloadURL,
} from 'firebase/storage';
import { CommonService } from './common.service';
import { sign } from 'jsonwebtoken-esm';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import {
  VideoEditor,
  ThumbnailOptions,
  EditOptions,
} from '@whiteguru/capacitor-plugin-video-editor';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { post, get } from 'superagent';
import { LoadingService } from './loading.service';
import { Post } from 'src/app/models/post';
import { User } from 'src/app/models/user';
import { DbService, leftJoinDocument } from './firebase.service';
import { take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { AuthService } from './auth.service';
import { FileOpener } from '@capacitor-community/file-opener';
import * as crypto from 'crypto';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  postList: Array<Post> = [];
  userList: Array<User> = [];

  isActiveSearchSub: Subscription;
  searchSub = new BehaviorSubject({ type: null, keyword: null });

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private alert: AlertService,
    private common: CommonService,
    private mediaCapture: MediaCapture,
    private loader: LoadingService,
    private db: DbService,
    private afs: AngularFirestore,
    private auth: AuthService,
    private filePlug: File
  ) {}

  /** 게시물 불러오기 */
  async getPost() {
    if (!this.auth.user) {
      return;
    }

    this.postList = await this.db
      .collection$('post', (ref: any) => ref.orderBy('dateCreated', 'desc'))
      .pipe(take(1))
      .toPromise()
      .then(async (postList) => {
        return await Promise.all(
          postList
            .filter(({ id, userId }) => {
              return !(
                this.auth.user.reportList?.includes(id) ||
                this.auth.user.reportList?.includes(userId)
              );
            })
            .map(async (post) => {
              return await of(post)
                .pipe(leftJoinDocument(this.afs, 'userId', 'members'), take(1))
                .toPromise()
                .then(async (post: Post) => {
                  post.commentCount = await this.getCommentCount(post.id);
                  return post;
                });
            })
        );
      });
  }

  // 1. foreEach map
  // array => object

  // 2. rxjs pipe map .pipe(map(e=>{

  // => array
  // }))

  /** 게시물 정보 변경 */
  changePostInfo(postId: string, key: string, value: any) {
    this.postList[this.postList.findIndex(({ id }) => id === postId)][key] =
      value;
  }

  /** 댓글 수 불러오기 */
  async getCommentCount(postId: string) {
    const { size } = await this.afs
      .collection('comment', (ref: any) => ref.where('postId', '==', postId))
      .get()
      .toPromise();
    return size;
  }

  /** 유저 불러오기 */
  async getUser() {
    this.userList = await new Promise<Array<User>>((resolve) => {
      const filter = (ref: any) => {
        return ref
          .where('exitSwitch', '==', false)
          .where('authenticationStatus', '==', 'approval')
          .orderBy('nickname', 'asc');
      };
      const userSub = this.db
        .collection$('members', (ref: any) => filter(ref))
        .subscribe(async (userList) => {
          const { size } = await this.afs
            .collection('members', (ref: any) => filter(ref))
            .get()
            .toPromise();
          if (size === userList?.length) {
            userSub.unsubscribe();
            resolve(userList);
          }
        });
    });
  }

  /** 뒤로 가기 */
  async goBack() {
    const url = this.router.url;
    await this.navCtrl.pop();
    if (url === this.router.url) {
      this.navCtrl.navigateBack('/tabs/home');
    }
  }

  /** 뒤로 가기 메시지 */
  async goBackAlert() {
    this.alert
      .cancelOkBtn(
        '',
        "If you leave, your post won't be saved. Are you sure you want to continue?"
      )
      .then((res) => {
        if (res) {
          this.goBack();
        }
      });
  }

  /** 유효성 검사 */
  validation(type: 'email' | 'password' | 'nickname', value: string) {
    let regExp: RegExp;
    if (type === 'email') {
      regExp =
        /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    } else if (type === 'password') {
      regExp = /^[a-zA-Z0-9]{6,16}$/;
    } else if (type === 'nickname') {
      regExp = /^[a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{1,20}$/;
    }
    return regExp.test(value);
  }

  /**
   * 사진 가져오기
   *
   * @param type 'Camera': 카메라로 사진 찍기, 'Photos': 갤러리에서 사진 가져오기
   * @returns dataUrl
   */
  getPicture(type: 'Camera' | 'Photos') {
    return new Promise<string>(async (resolve, reject) => {
      const imageOptions: ImageOptions = {
        quality: 60,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource[type],
      };
      Camera.getPhoto(imageOptions).then(
        ({ dataUrl }) => resolve(dataUrl),
        (error) => reject(error)
      );
    });
  }

  /**
   * 동영상 촬영
   *
   * @returns thumbnail, blob, fileName
   */
  captureVideo() {
    return new Promise<{ thumbnail: string; blob: Blob; fileName: string }>(
      (resolve, reject) => {
        this.mediaCapture.captureVideo().then(
          async (files) => {
            await this.loader.show();

            const thumbnailOptions: ThumbnailOptions = {
              path: files[0].fullPath,
              width: 1024,
              height: 1024,
            };
            const editOptions: EditOptions = {
              path: files[0].fullPath,
              transcode: { width: 1024, height: 1024, keepAspectRatio: true },
            };

            let thumbnail, blob;
            // thumbnail
            await VideoEditor.thumbnail(thumbnailOptions)
              .then(async ({ file: { path } }) => {
                return await Filesystem.readFile({ path }).then(({ data }) => {
                  thumbnail = `data:image/jpeg;base64,${data}`;
                });
              })
              .catch((err) => {
                this.loader.hide();
                console.log('VideoEditor thumbnail', err);
              });

            // blob
            await VideoEditor.edit(editOptions)
              .then(async ({ file: { path } }) => {
                return this.filePlug
                  .resolveLocalFilesystemUrl(path)
                  .then((entry: any) => {
                    entry.file(async (file) => {
                      const blobParts = [];
                      const chunkSize = 1024 * 1024 * 10; // 10MB
                      let offset = 0;

                      while (offset < file.size) {
                        const chunk = await this.readChunk(
                          file,
                          offset,
                          chunkSize
                        );

                        blobParts.push(chunk);
                        offset += chunkSize;
                      }
                      blob = new Blob(blobParts, { type: file.type });

                      console.log('files[0].name', files[0].name);
                      resolve({ thumbnail, blob, fileName: files[0].name });
                      this.loader.hide();
                    });
                  })
                  .catch((error) => {
                    console.log('error', error);
                  });
              })
              .catch((err) => {
                this.loader.hide();
                console.log('VideoEditor edit', err);
              });
          },
          (error) => reject(error)
        );
      }
    );
  }

  getVideoBlob(file) {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const blob = new Blob([reader.result], { type: file.type });
        resolve(blob);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }

  // 용량이 큰 동영상 파일을 나눠서 진행하기
  readChunk(file: any, offset: number, length: number) {
    // return new Promise<Blob>((resolve, reject) => {
    //   let FileReader: new () => FileReader = ((window as any).FileReader as any)
    //     .__zone_symbol__OriginalDelegate;

    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     const blob = new Blob([reader.result as ArrayBuffer]);
    //     resolve(blob);
    //   };
    //   const blob = file.slice(offset, offset + length);
    //   reader.readAsArrayBuffer(blob);
    // });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        // resolve(new Uint8Array(event.target.result));
        const blob = new Blob([reader.result as ArrayBuffer]);
        resolve(blob);
      };

      reader.onerror = reject;
      const slice = file.slice(offset, offset + length);
      reader.readAsArrayBuffer(slice);
    });
  }

  /**
   * 동영상 가져오기
   * @returns thumbnail, blob, fileName
   */
  getVideo() {
    return new Promise<{ thumbnail: string; blob: Blob; fileName: string }>(
      (resolve, reject) => {
        FilePicker.pickVideos().then(
          async ({ files: [fileData] }) => {
            await this.loader.show();
            console.log('fileData', fileData);
            console.log('fileData.path', fileData.path);

            const thumbnailOptions: ThumbnailOptions = {
              path: fileData.path,
              width: 1024,
              height: 1024,
            };
            const editOptions: EditOptions = {
              path: fileData.path,
              transcode: { width: 1024, height: 1024, keepAspectRatio: true },
            };

            let thumbnail, blob;
            // thumbnail
            await VideoEditor.thumbnail(thumbnailOptions)
              .then(async ({ file: { path } }) => {
                return await Filesystem.readFile({ path }).then(({ data }) => {
                  thumbnail = `data:image/jpeg;base64,${data}`;
                });
              })
              .catch((err) => {
                this.loader.hide();
                console.log('VideoEditor thumbnail', err);
              });

            // blob
            await VideoEditor.edit(editOptions)
              .then(async ({ file: { path } }) => {
                console.log('path', path);
                return this.filePlug
                  .resolveLocalFilesystemUrl(path)
                  .then((entry: any) => {
                    entry.file(async (file) => {
                      const blobParts = [];
                      const chunkSize = 1024 * 1024 * 10; // 10MB
                      let offset = 0;

                      while (offset < file.size) {
                        const chunk = await this.readChunk(
                          file,
                          offset,
                          chunkSize
                        );

                        blobParts.push(chunk);
                        offset += chunkSize;
                      }
                      blob = new Blob(blobParts, { type: file.type });
                      console.log('thumbnail', thumbnail);
                      console.log('blob', blob);
                      console.log('fileData.name', fileData.name);

                      resolve({ thumbnail, blob, fileName: fileData.name });
                      this.loader.hide();
                    });
                  })
                  .catch((error) => {
                    console.log('error', error);
                  });
              })
              .catch(async (err) => {
                this.loader.hide();
                //디바이스 정보 가져오기
                const deviceInfo = await Device.getInfo();
                console.log('deviceInfo', deviceInfo);

                console.log('VideoEditor edit', err);
                if (
                  err.message ===
                  'Transcode failed: Multiple channel to multiple channel mixing is not supported'
                ) {
                  this.alert.okBtn(
                    '',
                    'Please update the Android software of the device to the latest version.'
                  );
                }
              });
          },
          (error) => {
            this.loader.hide();
            reject(error);
          }
        );
      }
    );
  }

  /**
   * 파일 가져오기
   * @returns file
   */
  getFile() {
    return new Promise<any>((resolve, reject) => {
      FilePicker.pickFiles({ readData: true }).then(
        ({ files: [file] }) => resolve(file),
        (error) => reject(error)
      );
    });
  }

  /**
   * 파일 업로드
   * @param type 'img' or 'file'
   * @param data dataUrl or File
   * @param path e.g. 'profile/img'
   * @returns url
   */
  uploadFile(type: 'img' | 'file', data: any, path: string) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const URL =
          'https://storage.googleapis.com/indiefilmart-9787b.appspot.com/';
        const storage = getStorage();
        if (type === 'img') {
          const fullPath = `${path}/${this.common.generateFilename()}.${
            data.split('/')[1].split(';')[0]
          }`;
          const storageRef = ref(storage, fullPath);
          await uploadString(storageRef, data, 'data_url');
          resolve(URL + fullPath);
        } else {
          const fullPath = `${path}/${this.common.generateFilename()}.${
            data.name.split('.')[1]
          }`;
          const storageRef = ref(storage, fullPath);
          if (data.blob) {
            await uploadBytes(storageRef, data.blob, {
              contentType: data.mimeType,
            });
          } else {
            await uploadString(
              storageRef,
              `data:${data.mimeType};base64,${data.data}`,
              'data_url'
            );
          }
          resolve(URL + fullPath);
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  /** 파일 다운로드 */
  async downloadFile(url: string, fileName: string) {
    console.log('fileName : ', fileName);
    console.log('url : ', url);
    try {
      const [buffer, blob] = await Promise.all([
        fetch(url).then((res) => res.arrayBuffer()),
        fetch(url).then((res) => res.blob()),
      ]);

      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const { uri } = await Filesystem.writeFile({
        path: fileName,
        data: `data:${blob.type};base64,${window.btoa(binary)}`,
        directory: Directory.Documents,
        recursive: true,
      });

      await FileOpener.open({ filePath: uri });
    } catch {}
  }

  /**
   * 웹 브라우저에서 firebase storage 파일을
   * 다운로드하려 할 때 사용할 함수
   *
   * @param link firebase storage url 경로
   * @param fileName 파일 이름
   */
  downloadFileWeb(link: string, fileName: string) {
    const storage = getStorage();
    const starsRef = ref(storage, link);

    // Get the download URL
    getDownloadURL(starsRef)
      .then((url) => {
        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const downloadedFile = xhr.response;
          const blob = new Blob([downloadedFile], {
            type: 'application/octet-stream',
          });
          const objUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = objUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  }

  /**
   * 업로드된 파일 삭제
   * @param url 업로드된 파일 경로
   * */
  deleteFile(url: string) {
    const fullPath = url.split('appspot.com/')[1];
    const storage = getStorage();
    const desertRef = ref(storage, fullPath);
    deleteObject(desertRef);
  }

  /**
   * 영상 업로드
   * @returns 업로드된 영상의 업로드 파일 키
   */
  uploadVideo(
    type: 'trailer' | 'screener' | 'introduceVideo' | 'video',
    blob: any,
    fileName: string
  ) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        let category_key: string;
        if (type === 'trailer') {
          category_key = 's48zkq1rp038kv93';
        } else if (type === 'screener') {
          category_key = '8mymh0eylr9tkgtd';
        } else if (type === 'introduceVideo') {
          category_key = 'wre3wykkpd3nrdsu';
        } else if (type === 'video') {
          category_key = 'fs417w1xclyshh2t';
        }
        const { uploadUrl, cid } = await post(
          'https://api.kr.kollus.com/0/media_auth/upload/create_url?access_token=icoqztsxxojmprak'
        )
          .field('category_key', category_key)
          .then(({ text }) => {
            const { error, message, result } = JSON.parse(text);
            if (error !== 0) {
              throw new Error(message);
            }
            return {
              uploadUrl: result.upload_url,
              cid: result.upload_file_key,
            };
          });

        await post(uploadUrl)
          .field('accept', 'application/json')
          .attach('upload-file', blob, fileName)
          .then(({ text }) => {
            const { error, message } = JSON.parse(text);
            if (error !== 0) {
              throw new Error(message);
            }
          });

        resolve(cid);
      } catch (error) {
        reject(error.message || error);
      }
    });
  }

  /**
   * 업로드된 영상 URL 불러오기
   * @param cid 업로드 파일 키
   * @returns 업로드된 영상 URL
   */
  async getVideoUrl(cid: string) {
    let size;

    return await get(
      `https://api.kr.kollus.com/0/media/library/media_content/${cid}?access_token=icoqztsxxojmprak`
    )
      .then(async ({ text }) => {
        const { error, result } = JSON.parse(text);

        if (error !== 0 || 0 >= result.item.channels.length) {
          return {
            message: '영상을 업로드하고 있습니다.\n잠시 후 다시 시도해 주세요.',
          };
        }
        console.log('cid', cid);

        await get(
          `https://api-vod-kr.kollus.com/api/v0/vod/media-contents/${cid}?access_token=icoqztsxxojmprak`
        ).then((result) => {
          console.log('result', result.body.data);
          size = result.body.data.media_information.video;
          console.log('size', size);

          return size;
        });

        const mckey = result.item.channels[0].media_content_key; // 미디어 컨텐츠 키
        const securityKey = 'postfin'; // 콜러스 콘솔 -> 설정 페이지 -> 서비스 계정 키
        const customKey =
          '3e5daf8a0457a2d63d33640076ac8b84a1b21190a63d64db9c594b52f352499b'; // 콜러스 콘솔 -> 설정 페이지 -> 사용자 키
        const accessKey = 'DRsg0Fyt1Yum0ipWfxakD2REEsRd3Mci'; // inka pallycon 콘솔 -> drm setting -> Access Key
        const siteKey = 'XVWgsdiY9WXrzhxulYUZyL8GTRAToZPp'; // inka pallycon 콘솔 -> drm setting -> Site Key
        const siteID = '1CVG'; // inka pallycon 콘솔 -> drm setting -> Site ID
        const clientUserId = 'postfin'; //홈페이지 사용자 아이디
        const iv = '0123456789abcdef';

        const agent = window.navigator.userAgent;
        const supportBrowser = [
          'CriOS',
          'Edge',
          'Edg',
          'FireFox',
          'Chrome',
          'Safari',
          'Opera',
          'MSIE',
          'Trident',
        ];

        let userBrowser = '';
        let drmType = '';
        let streamingType = '';
        let token: any = {
          policy_version: 2,
          playback_policy: {
            persistent: false,
          },
          security_policy: [
            {
              widevine: {
                override_device_revocation: true,
              },
            },
          ],
        };

        if (String(agent).match('iPhone')) {
          userBrowser = 'Safari';
        }

        supportBrowser.some((browser) => {
          if (String(agent).match(browser)) {
            userBrowser = browser;
            return true;
          }
        });

        switch (userBrowser) {
          case 'MSIE':
          case 'Trident':
          case 'Edge':
          case 'Edg':
          case 'Opera':
            drmType = 'PlayReady';
            streamingType = 'dash';
            break;
          case 'Chrome':
          case 'Firefox':
            drmType = 'Widevine';
            streamingType = 'dash';
            break;
          case 'Safari':
          case 'CriOS':
            drmType = 'FairPlay';
            streamingType = 'hls';
            break;
        }

        if (String(agent).match('Macintosh') && String(agent).match('Edg')) {
          drmType = 'Widevine';
          streamingType = 'dash';
        }

        console.log('siteKey', siteKey);
        console.log('iv', iv);

        const cipher = crypto.createCipheriv('aes-256-cbc', siteKey, iv);
        console.log('cipher', cipher);

        token = cipher.update(JSON.stringify(token), 'utf8', 'base64');
        token += cipher.final('base64');

        const timeStamp = new Date().toISOString();
        let hash =
          accessKey + drmType + siteID + clientUserId + cid + token + timeStamp;
        hash = crypto.createHash('sha256').update(hash).digest('base64');

        let inkaPayload: any = {
          drm_type: drmType,
          site_id: siteID,
          user_id: clientUserId,
          cid,
          token,
          timestamp: timeStamp,
          hash,
        };
        inkaPayload = JSON.stringify(inkaPayload);
        inkaPayload = Buffer.from(inkaPayload).toString('base64');

        console.log('inkaPayload', inkaPayload);

        const payload = {
          expt: 4828204800000,
          cuid: clientUserId,
          mc: [
            {
              mckey,
              drm_policy: {
                kind: 'inka',
                streaming_type: streamingType,
                data: {
                  license_url:
                    'https://license.pallycon.com/ri/licenseManager.do',
                  certificate_url:
                    'https://license.pallycon.com/ri/fpsKeyManager.do?siteId=' +
                    siteID,
                  custom_header: {
                    key: 'pallycon-customdata-v2',
                    value: inkaPayload,
                  },
                },
              },
            },
          ],
        };
        console.log('payload', payload);

        return {
          url: `https://v.kr.kollus.com/s?jwt=${sign(
            payload,
            securityKey
          )}&custom_key=${customKey}`,
          info: result.item,
          size: size,
        };
      })
      .catch((err) => {
        console.log('err!!!', err);
        return err;
      });
  }
}
