import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/account/login/login.module').then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: 'join',

    loadChildren: () =>
      import('./pages/account/join/join.module').then((m) => m.JoinPageModule),
  },
  // {
  //   path: 'sign-in',
  //   loadChildren: () =>
  //     import('./pages/account/sign-in/sign-in.module').then(
  //       (m) => m.SignInPageModule
  //     ),
  // },
  {
    path: 'sign-in',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/account/sign-in/sign-in.module').then(
            (m) => m.SignInPageModule
          ),
      },
      {
        path: 'join',
        loadChildren: () =>
          import('./pages/account/join/join.module').then(
            (m) => m.JoinPageModule
          ),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('./pages/account/reset-password/reset-password.module').then(
            (m) => m.ResetPasswordPageModule
          ),
      },
    ],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/account/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: 'comment',
    loadChildren: () =>
      import('./pages/comment/comment.module').then((m) => m.CommentPageModule),
  },
  {
    path: 'modal-report',
    loadChildren: () =>
      import('./pages/modal-report/modal-report.module').then(
        (m) => m.ModalReportPageModule
      ),
  },
  {
    path: 'modal-like',
    loadChildren: () =>
      import('./pages/modal-like/modal-like.module').then(
        (m) => m.ModalLikePageModule
      ),
  },
  {
    path: 'modal-image-detail',
    loadChildren: () =>
      import('./pages/modal-image-detail/modal-image-detail.module').then(
        (m) => m.ModalImageDetailPageModule
      ),
  },
  {
    path: 'modal-preview',
    loadChildren: () =>
      import('./pages/modal-preview/modal-preview.module').then(
        (m) => m.ModalPreviewPageModule
      ),
  },
  {
    path: 'modal-map',
    loadChildren: () =>
      import('./pages/modal-map/modal-map.module').then(
        (m) => m.ModalMapPageModule
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'notifications-detail',
    loadChildren: () =>
      import('./pages/notifications-detail/notifications-detail.module').then(
        (m) => m.NotificationsDetailPageModule
      ),
  },
  {
    path: 'question',
    loadChildren: () =>
      import('./pages/question/question.module').then(
        (m) => m.QuestionPageModule
      ),
  },
  {
    path: 'question-write',
    loadChildren: () =>
      import('./pages/question-write/question-write.module').then(
        (m) => m.QuestionWritePageModule
      ),
  },
  {
    path: 'question-detail',
    loadChildren: () =>
      import('./pages/question-detail/question-detail.module').then(
        (m) => m.QuestionDetailPageModule
      ),
  },
  {
    path: 'mypage',
    loadChildren: () =>
      import('./pages/mypage/mypage.module').then((m) => m.MypagePageModule),
  },
  {
    path: 'modal-menu',
    loadChildren: () =>
      import('./pages/modal-menu/modal-menu.module').then(
        (m) => m.ModalMenuPageModule
      ),
  },
  {
    path: 'edit-profile',
    loadChildren: () =>
      import('./pages/edit-profile/edit-profile.module').then(
        (m) => m.EditProfilePageModule
      ),
  },
  {
    path: 'saved-posts',
    loadChildren: () =>
      import('./pages/saved-posts/saved-posts.module').then(
        (m) => m.SavedPostsPageModule
      ),
  },
  {
    path: 'only-me',
    loadChildren: () =>
      import('./pages/only-me/only-me.module').then((m) => m.OnlyMePageModule),
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('./pages/membership/membership.module').then(
        (m) => m.MembershipPageModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./pages/search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'upload',
    loadChildren: () =>
      import('./pages/upload/upload.module').then((m) => m.UploadPageModule),
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
  },
  {
    path: 'notice',
    loadChildren: () =>
      import('./pages/notice/notice.module').then((m) => m.NoticePageModule),
  },
  {
    path: 'notice-detail',
    loadChildren: () =>
      import('./pages/notice-detail/notice-detail.module').then(
        (m) => m.NoticeDetailPageModule
      ),
  },
  {
    path: 'user-profile/:id',
    loadChildren: () =>
      import('./pages/user-profile/user-profile.module').then(
        (m) => m.UserProfilePageModule
      ),
  },
  {
    path: 'modal-director',
    loadChildren: () =>
      import('./pages/modal-director/modal-director.module').then(
        (m) => m.ModalDirectorPageModule
      ),
  },
  {
    path: 'modal-user',
    loadChildren: () =>
      import('./pages/modal-user/modal-user.module').then(
        (m) => m.ModalUserPageModule
      ),
  },
  {
    path: 'modal-url',
    loadChildren: () =>
      import('./pages/modal-url/modal-url.module').then(
        (m) => m.ModalUrlPageModule
      ),
  },
  {
    path: 'chat-detail',
    loadChildren: () =>
      import('./pages/chat-detail/chat-detail.module').then(
        (m) => m.ChatDetailPageModule
      ),
  },
  {
    path: 'upload-write',
    loadChildren: () =>
      import('./pages/upload-write/upload-write.module').then(
        (m) => m.UploadWritePageModule
      ),
  },

  {
    path: 'detail-film',
    loadChildren: () =>
      import('./pages/detail-film/detail-film.module').then(
        (m) => m.DetailFilmPageModule
      ),
  },
  {
    path: 'detail-script',
    loadChildren: () =>
      import('./pages/detail-script/detail-script.module').then(
        (m) => m.DetailScriptPageModule
      ),
  },
  {
    path: 'detail-actor',
    loadChildren: () =>
      import('./pages/detail-actor/detail-actor.module').then(
        (m) => m.DetailActorPageModule
      ),
  },
  {
    path: 'detail-feed',
    loadChildren: () =>
      import('./pages/detail-feed/detail-feed.module').then(
        (m) => m.DetailFeedPageModule
      ),
  },
  {
    path: 'upload-feed-write',
    loadChildren: () =>
      import('./pages/upload-feed-write/upload-feed-write.module').then(
        (m) => m.UploadFeedWritePageModule
      ),
  },
  {
    path: 'upload-actor-write',
    loadChildren: () =>
      import('./pages/upload-actor-write/upload-actor-write.module').then(
        (m) => m.UploadActorWritePageModule
      ),
  },
  {
    path: 'upload-film-write',
    loadChildren: () =>
      import('./pages/upload-film-write/upload-film-write.module').then(
        (m) => m.UploadFilmWritePageModule
      ),
  },
  {
    path: 'upload-script-write',
    loadChildren: () =>
      import('./pages/upload-script-write/upload-script-write.module').then(
        (m) => m.UploadScriptWritePageModule
      ),
  },
  {
    path: 'modal-video-detail',
    loadChildren: () =>
      import('./pages/modal-video-detail/modal-video-detail.module').then(
        (m) => m.ModalVideoDetailPageModule
      ),
  },
  {
    path: 'modal-actor',
    loadChildren: () =>
      import('./pages/modal-actor/modal-actor.module').then(
        (m) => m.ModalActorPageModule
      ),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./pages/terms/terms.module').then((m) => m.TermsPageModule),
  },
  {
    path: 'follwer-following-modal',
    loadChildren: () =>
      import(
        './pages/follwer-following-modal/follwer-following-modal.module'
      ).then((m) => m.FollwerFollowingModalPageModule),
  },
  {
    path: 'iamhere',
    loadChildren: () =>
      import('./pages/iamhere/iamhere.module').then((m) => m.IamherePageModule),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
